import React, { useState } from "react";

const newPost = async (username, title, content) => {
  const url = "https://my-worker.albert-lai.workers.dev/posts";
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      username: username,
      title: title,
      content: content,
    }),
  });
  try {
    const resp_json = await response.json();
    return resp_json;
  } catch (err) {
    console.log(err);
  }
};

export default function Form(props) {
  const [username, setUsername] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (username === "" || title === "" || content === "") {
      alert("Please fill in all fields");
      return;
    }
    await newPost(username, title, content);
    await props.fetchPosts();
    setUsername("");
    setTitle("");
    setContent("");
  };

  return (
    <div className="create-post-container post">
      <h2>Create New Post</h2>
      <form onSubmit={handleSubmit}>
        <label>*username:</label>
        <br />
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <br />
        <br />
        <label>*Post Title:</label>
        <br />
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <br />
        <br />
        <label>*Post Content:</label>
        <br />
        <textarea
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <br />
        <br />
        <br />
        <input type="submit" />
      </form>
    </div>
  );
}
