import "./App.css";
import { React, useEffect, useState } from "react";
import Post from "./components/Post";
import Form from "./components/Form";

const getPosts = async () => {
  const url = "https://my-worker.albert-lai.workers.dev/posts";
  const response = await fetch(url, {
    method: "GET",
  });
  try {
    const resp_json = await response.json();
    return resp_json;
  } catch (err) {
    console.log(err);
  }
};

function App() {
  const [posts, setPosts] = useState([]);

  const fetchPosts = async () => {
    const posts = await getPosts();
    setPosts(JSON.parse(posts));
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <div className="App">
      <div className="posts-container">
        <h1>Posts</h1>
        {posts.map((post, idx) => (
          <Post post={post} key={idx} />
        ))}
      </div>
      <Form fetchPosts={fetchPosts} />
    </div>
  );
}

export default App;
