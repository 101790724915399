import "../App.css";

export default function Post(props) {
  return (
    <div className="post">
      <div className="post-author">@{props.post.username}</div>
      <div className="post-header">{props.post.title}</div>
      <div className="post-body">{props.post.content}</div>
    </div>
  );
}
